import React from "react";
import Stats from "./Stats";
import RbtNftTrans from "./RbtNftTrans";
import ConTrans from "./ConTrans";
import Search from "./TransactionsInfo/Search";
import LineChart from "../Samples/Line";
import BarChart from "../Samples/BarChart";

const Home = () => {
  return (
    <>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="title">Explorer</h1>
            </div>
            <Search />
          </div>
        </div>
        <Stats />
        <div className="container mt-5">
          <div className="row">
           
          <div className="col-md-12">
              <RbtNftTrans />
            </div>
            {/* <div className="col-md-6">
              <BarChart />
            </div> */}
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            
            {/* <div className="col-md-12 mmb-5">
              <LineChart />
            </div> */}
            <div className="col-md-12 mmb-5">
              <BarChart />
            </div>

            {/* <div className="col-md-6">
              <ConTrans />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
