import React, { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link, NavLink } from "react-router-dom";
import { BiSun, BiMoon } from "react-icons/bi";
import { ThemeContext } from "./GlobalComponentes/ThemeProvider";
import logo from "./Assets/logo_white.png";
import { BsCheck2All } from "react-icons/bs";

const Header = () => {
  const { theme, setThemeMode } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(theme);

  useEffect(() => {
    setThemeMode(darkMode);
    //console.log(darkMode);
  }, [darkMode]);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
            <NavLink className="nav-link" to="/contracttransactions">Contract Txs</NavLink>

            <NavLink className="nav-link" to="/tokens">
              Tokens
            </NavLink>
            <NavLink className="nav-link" to="/wallets">
              Wallets
            </NavLink>
            <Nav.Link className="mainnet">
              {" "}
              <span>
                <BsCheck2All />
              </span>{" "}
              Mainnet
            </Nav.Link>
            <NavLink
              className={`${
                darkMode ? "text-dark-primary" : "text-light-primary"
              } nav-link themebtn`}
              style={{ paddingTop: "13px", color: "#fff" }}
              onClick={() => setDarkMode(!darkMode)}
            >
              {darkMode ? <BiSun size="1.7rem" /> : <BiMoon size="1.7rem" />}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
