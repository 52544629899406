import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsClipboard2, BsClipboard2CheckFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dateFormat from "dateformat";


const RbtNftTrans = () => {
  const [rbtnftData, setExplorerData] = useState([]);
  const [txid, setCopied] = useState("dymmyId");

  async function getResponse() {
    await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/LatestTransactions?page=1&pageSize=8"
    ).then((res) => res.json())
      .then((data) => {
      //  console.log(data)
        setExplorerData(data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
      getResponse();
      const timer = setTimeout(() => {
        getResponse();
      }, 2000);
      return () => clearTimeout(timer);
  }, []);

  return (
    <Card className="homecard">
      <h4 className="m-0"> RBT / NFT Transactions</h4>
      <hr />
      {rbtnftData.length != 0 ? (
        rbtnftData.map((item) => {
          return (
            <Card.Body key={item.transaction_id}>
              <div className="bloktransaction">
                <div className="txicon">Tx</div>
                <div className="txidandduration">
                  <p>
                    Id:
                    <Link to={"/transinfo/" + item.transaction_id}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {item.transaction_id}
                          </Tooltip>
                        }
                      >
                        <i>{item.transaction_id}</i>
                      </OverlayTrigger>
                    </Link>
                    <b>
                      <CopyToClipboard
                        text={item.transaction_id}
                        onCopy={(e) => {
                          setCopied(item.transaction_id);
                          setTimeout(() => {
                            setCopied("falseId");
                          }, 1500);
                        }}
                      >
                        {txid != item.transaction_id ? (
                          <BsClipboard2 />
                        ) : (
                          <BsClipboard2CheckFill />
                        )}
                      </CopyToClipboard>
                    </b>
                  </p>
                  <p>Creation Time: <i>{dateFormat(item.creationTime)}</i> &nbsp;&nbsp;  Duration: <i>{item.token_time} secs </i></p>
                </div>
                <div className="txfrmto">
                  <p>
                    From:{" "}
                    <Link to={"/userinfo/" + item.sender_did}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {item.sender_did}
                          </Tooltip>
                        }
                      >
                        <i>{item.sender_did}</i>
                      </OverlayTrigger>
                    </Link>
                    <b>
                      <CopyToClipboard
                        text={item.sender_did}
                        onCopy={(e) => {
                          setCopied(item.transaction_id + item.sender_did);
                          setTimeout(() => {
                            setCopied("falseId");
                          }, 1500);
                        }}
                      >
                        {txid != item.transaction_id + item.sender_did ? (
                          <BsClipboard2 />
                        ) : (
                          <BsClipboard2CheckFill />
                        )}
                      </CopyToClipboard>
                    </b>
                  </p>
                  <p>
                    To:
                    <Link to={"/userinfo/" + item.receiver_did}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {item.receiver_did}
                          </Tooltip>
                        }
                      >
                        <i>{item.receiver_did}</i>
                      </OverlayTrigger>
                    </Link>
                    <b>
                      <CopyToClipboard
                        text={item.receiver_did}
                        onCopy={(e) => {
                          setCopied(item.transaction_id + item.receiver_did);
                          setTimeout(() => {
                            setCopied("falseId");
                          }, 1500);
                        }}
                      >
                        {txid != item.transaction_id + item.receiver_did ? (
                          <BsClipboard2 />
                        ) : (
                          <BsClipboard2CheckFill />
                        )}
                      </CopyToClipboard>
                    </b>
                  </p>
                </div>
                <div className="txvolfee">
                  <p>
                    <span>{item.amount}</span> Volume
                  </p>
                  <p>
                    <span>0</span> Fee
                  </p>
                </div>
              </div>
            </Card.Body>
          );
        })
      ) : (
        <Card.Body>
          <p className="text-center">Loading ...</p>
        </Card.Body>
      )}
      <div className="viewall">
        <Link to="/rbtnfttransactions">View all</Link>
      </div>
    </Card>
  );
};

export default RbtNftTrans;
