import React, { Component } from "react";
import Tokenstable from "./Tokenstable";

export class Tokens extends Component {
  render() {
    return (
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Tokens info</h2>
              <Tokenstable />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Tokens;
