import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const UserInfo = () => {
  const { id } = useParams();
  const [contData, setExplorerData] = useState([]);
  const [usertData, setuserTransData] = useState([]);

  async function getuserTransactions() {
    await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/getTransactions/" + id + "/1/5"
    )
      .then((res) => res.json())
      .then((data) => {
       //console.log(data);
        setuserTransData(data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getResponse() {
    await fetch("https://rubix-explorer-api.azurewebsites.net/api/Explorer/userInfo/" + id)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setExplorerData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    getuserTransactions();
    getResponse();
  });


  return (
    <>
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>User info</h2>
              <Card>
                {contData.user_did != null ? (
                  <Card.Body>
                     <div className="table-responsive">
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>User id:</td>
                          <td>{contData.user_did}</td>
                        </tr>
                        <tr>
                          <td>Balance:</td>
                          <td>{(contData.balance).toFixed(3)}</td>
                        </tr>
                        <tr>
                          <td>New user id:</td>
                          <td>{contData.new_did}</td>
                        </tr>
                        <tr>
                          <td>Peer id:</td>
                          <td>{contData.peerid}</td>
                        </tr>
                        <tr>
                          <td>New peer id:</td>
                          <td>{contData.new_peerId}</td>
                        </tr>
                        
                      </tbody>
                    </Table>
                    </div>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <p className="text-center">Loading...</p>
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <h2>User transactions info</h2>
              <Card className="homecard">
                {usertData.length != 0 ? (
                  usertData.map((item) => {
                    return (
                      <Card.Body key={item.transaction_id}>
                        <div className="bloktransaction">
                          <div className="txicon">Tx</div>
                          <div className="txidandduration">
                            <p>
                              Id:
                              <Link>
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {item.transaction_id}
                                    </Tooltip>
                                  }
                                >
                                  <i>{item.transaction_id}</i>
                                </OverlayTrigger>
                              </Link>
                            </p>
                            <span>{item.token_time} secs </span>
                          </div>
                          <div className="txfrmto">
                            <p>
                              From:
                              <Link>
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {item.sender_did}
                                    </Tooltip>
                                  }
                                >
                                  <i>{item.sender_did}</i>
                                </OverlayTrigger>
                              </Link>
                            </p>
                            <p>
                              To:
                              <Link>
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {item.receiver_did}
                                    </Tooltip>
                                  }
                                >
                                  <i>{item.receiver_did}</i>
                                </OverlayTrigger>
                              </Link>
                            </p>
                          </div>
                          <div className="txvolfee">
                            <p>
                              <span>{item.amount}</span> Volume
                            </p>
                            <p>
                              <span>0</span> Fee
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    );
                  })
                ) : (
                  <Card.Body>
                    <p className="text-center">Loading ...</p>
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserInfo;