import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";

const Walletstable = () => {
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );

  const columns = [
    {
      name: "DID / Wallet id",
      selector: (row) => row.user_Did,
      //   render: function (row) {
      //     return '<Link to="/shop">' + row.token_id + "</NavLink>";
      //   },
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
    },
  ];

  const [tokenData, setExplorerData] = useState([]);
  const [pending, setPending] = useState(true);

  async function getResponse() {
    await fetch("https://rubix-explorer-api.azurewebsites.net/api/Explorer/top-balance-users?pageNumber=1&pageSize=30")
      .then((res) => res.json())
      .then((data) => {
       console.log(data);
        setExplorerData(data);
        setPending(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    getResponse();
    // const interval = setInterval(() => {
    //   getResponse();
    // }, 5000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <Card.Body>
        <DataTable
          columns={columns}
          data={tokenData}
          pagination
          progressPending={pending}
          expandableRowsComponent={ExpandedComponent}
          expandableRows
        />
      </Card.Body>
    </Card>
  );
};

export default Walletstable;
