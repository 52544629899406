import React, { Component } from "react";
import Walletstable from "./Walletstable";

export class Wallets extends Component {
  render() {
    return (
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Wallets info</h2>
              <Walletstable />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Wallets;
