import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Tokens from "./Tokens";
import Wallets from "./Wallets";
import Home from "./Home/Home";
import "./App.css";
import { useThemeHook } from "./GlobalComponentes/ThemeProvider";
import TransInfo from "./Home/TransactionsInfo/TransInfo";
import UserInfo from "./Home/TransactionsInfo/UserInfo";
import DataInfo from "./Home/TransactionsInfo/DataInfo";
import FullRbtNftTrans from "./Home/FullRbtNftTrans";
import FullConTrans from "./Home/FullConTrans";

function App() {
  const [theme] = useThemeHook();

  return (
    <main
      className={theme ? "bg-black" : "bg-light"}
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tokens" element={<Tokens />} />
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/transinfo/:id" element={<TransInfo />} />
          <Route path="/userinfo/:id" element={<UserInfo />} />
          <Route path="/datatokeninfo/:id" element={<DataInfo />} />
          <Route path="/rbtnfttransactions" element={<FullRbtNftTrans />} />
          <Route path="/contracttransactions" element={<FullConTrans />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </main>
  );
}

export default App;
