import React, { Component } from "react";
import {
  BsDiscord,
  BsTelegram,
  BsTwitter,
  BsLinkedin,
  BsFacebook,
  BsReddit,
  BsGithub,
} from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";

import { Link, NavLink } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <div className="footer section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 copyright">
              <p>Copyright © 2024 Rubix. All Rights Reserved.</p>
            </div>
            <div className="col-md-6">
              <ul className="footerlinks">
                <li>
                  <Link to="https://discord.gg/MNYPfhFEEk" target="_blank">
                    <BsDiscord />
                  </Link>
                </li>
                <li>
                  <Link to="https://t.me/rubixblockchain" target="_blank">
                    <BsTelegram />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/rubixchain" target="_blank">
                    <FaXTwitter />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/rubixnet"
                    target="_blank"
                  >
                    <BsLinkedin />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/RubixChain"
                    target="_blank"
                  >
                    <BsFacebook />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.reddit.com/r/rubixchain/"
                    target="_blank"
                  >
                    <BsReddit />
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/rubixchain" target="_blank">
                    <BsGithub />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
