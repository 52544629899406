import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsClipboard2, BsClipboard2CheckFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dateFormat from "dateformat";


const FullConTrans = () => {
  const [contData, setExplorerData] = useState([]);
  const [txid, setCopied] = useState("dymmyId");

  async function getResponse() {
    await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/get-latest-datatokens?page=1&pageSize=50"
    )
      .then((res) => res.json())
      .then((data) => {
       console.log(data);
        setExplorerData(data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    getResponse();
    const interval = setInterval(() => {
      getResponse();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getResponse();
  //   }, 2000);
  //   return () => clearTimeout(timer);
  //   //getResponse();
  // }, []);
  
  return (
    <>
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="m-0"> Contract Transactions</h1>
              <Card className="homecard mt-2">
                {contData.length != 0 ? (
                  contData.map((item) => {
                    return (
                      <Card.Body key={item.transaction_id}>
                        <div className="bloktransaction">
                          <div className="txicon">Tx</div>
                          <div className="txidandduration">
                            <p>
                              Id:
                              <Link
                                to={"/datatokeninfo/" + item.transaction_id}
                              >
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {item.transaction_id}
                                    </Tooltip>
                                  }
                                >
                                  <i>{item.transaction_id}</i>
                                </OverlayTrigger>
                              </Link>
                              <b>
                                <CopyToClipboard
                                  text={item.transaction_id}
                                  onCopy={(e) => {
                                    setCopied(item.transaction_id);
                                    setTimeout(() => {
                                      setCopied("falseId");
                                      // console.log(txid);
                                    }, 1500);
                                  }}
                                >
                                  {txid != item.transaction_id ? (
                                    <BsClipboard2 />
                                  ) : (
                                    <BsClipboard2CheckFill />
                                  )}
                                </CopyToClipboard>
                              </b>
                            </p>
                            <span>{item.token_time} secs </span>
                          </div>
                          <div className="txfrmto">
                            <p>
                              Commiter:
                              <Link>
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {item.commiter}
                                    </Tooltip>
                                  }
                                >
                                  <i>{item.commiter}</i>
                                </OverlayTrigger>
                              </Link>
                              <b>
                                <CopyToClipboard
                                  text={item.commiter}
                                  onCopy={(e) => {
                                    setCopied(
                                      item.commiter + item.transaction_id
                                    );
                                    setTimeout(() => {
                                      setCopied("false");
                                    }, 1500);
                                  }}
                                >
                                  {txid !=
                                  item.commiter + item.transaction_id ? (
                                    <BsClipboard2 />
                                  ) : (
                                    <BsClipboard2CheckFill />
                                  )}
                                </CopyToClipboard>
                              </b>
                              <br />
                                <span>Creation Time: <i>{dateFormat(item.creation_time)}</i> </span>
                            </p>
                            
                          </div>
                          <div className="txvolfee">
                            <p>
                              <span>{item.volume}</span> Volume
                            </p>
                            <p>
                              <span>0</span> Fee
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    );
                  })
                ) : (
                  <Card.Body>
                    <p className="text-center">Loading ...</p>
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullConTrans;
