import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BiSearchAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BsClipboard2, BsClipboard2CheckFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";


const Search = () => {
  const [hashedid, setHashedid] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [showuri, setUri] = useState(false);
  const [uiurl, seturlData] = useState([]);
  const navigate = useNavigate();
  const [txid, setCopied] = useState("dymmyId");


  function goToPage(path) {
    navigate("/" + path + "/" + hashedid);
    setHashedid("");
  }

  const handleHashedidChange = (event) => {
    setHashedid(event.target.value);
    if (hashedid.length == 0) {
      setInvalid(false);
      setUri(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (hashedid != "" && showuri ==  false) {
      
      if (hashedid.length >= 60) {
        goToPage("transinfo");
      } else if (hashedid.length == 59 || 46) {
        goToPage("userinfo");
      }
      setInvalid(true);

    } else if(hashedid != "" && showuri ==  true){
      getUrlResponse(hashedid);
    } else {
      setInvalid(true);
      setUri(false);

    }
    
  };

  async function getUrlResponse(hashedid) {
    await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/generate-short-url?url=https://rubixexplorer.com/transinfo/"+hashedid
      ).then((res) => res.text())
      .then((data)=>{
        console.log(data);
        seturlData(data);
      }) 
  }




  return (
    <div className="col-md-12">
    <form onSubmit={handleSubmit} className="searchform">
      <InputGroup>
        <Form.Control
          onChange={handleHashedidChange}
          value={hashedid}
          placeholder="Search by Transaction hash / Token hash / DID"
          aria-label="Search by Transaction hash / Token hash / DID"
        />
         <Button type="submit" onClick={() => {setUri(true)}} className="btn-custom2">
         Generate short url
        </Button>
        <Button type="submit" onClick={() => setUri(false)} className="btn-custom">
          <BiSearchAlt size="1.7rem" />
        </Button>
      </InputGroup>
      {invalid ? <p>Invalid hash...</p> : ""}
    </form>
  
{showuri ? 
<><div className="displayShrturl">
   
      {uiurl == '' ? <p>Loading...</p>: <p>{uiurl} <span>
        
      <CopyToClipboard
                        text={uiurl}
                        onCopy={(e) => {
                          setCopied(uiurl);
                          setTimeout(() => {
                            setCopied("falseId");
                          }, 1500);
                        }}
                      >
                        {txid != uiurl ? (
                          <BsClipboard2 />
                        ) : (
                          <BsClipboard2CheckFill />
                        )}
                      </CopyToClipboard>
        </span> </p> }
      
      
    </div>
</>
: ""}
</div>
  );
};

export default Search;
