/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

const TransInfo = () => {
  const { id } = useParams();
  

  const [contData, setExplorerData] = useState([]);
  const [uiurl, seturlData] = useState([]);

  async function getResponse() {
    await fetch("https://rubix-explorer-api.azurewebsites.net/api/Explorer/transactionInfo/" + id)
      .then((res) => res.json())
      .then((data) => {
       // console.log(data);
        setExplorerData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


    async function getUrlResponse() {
      await fetch(
        "https://rubix-explorer-api.azurewebsites.net/api/Explorer/generate-short-url?url=https://rubixexplorer.com/transinfo/"+id
        ).then((res) => res.text())
        .then((data)=>{
         // console.log(data);
          seturlData(data);
        })
      
    }


    // async function getUrlResponse() {
   

  //   await fetch(
  //     "https://rubix-explorer-api.azurewebsites.net/api/Explorer/generate-short-url?url=https://rubixexplorer.com/transinfo/d05cdaa26c8c332c44792f618a268471a1af9f5da78c3cab5664de52b494cb86")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       seturlData(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }



  useEffect(() => {
    getUrlResponse();
    getResponse();
 });

  return (
    <>
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Transaction info</h2>
              <Card>
                {contData.receiver_did != null ? (
                  <Card.Body>
                     <div className="table-responsive">
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Transaction id:</td>
                          <td>{contData.transaction_id}</td>
                        </tr>
                        <tr>
                          <td>From:</td>
                          <td>{contData.sender_did}</td>
                        </tr>
                        <tr>
                          <td>To:</td>
                          <td>{contData.receiver_did}</td>
                        </tr>
                        <tr>
                          <td>Volume:</td>
                          <td>{contData.amount}</td>
                        </tr>
                        <tr>
                          <td>Creation time:</td>
                          <td>{contData.creationTime}</td>
                        </tr>
                        <tr>
                          <td>Token id:</td>
                          <td>{contData.token}</td>
                        </tr>
                        <tr>
                          <td>Short url:</td>
                          <td>{uiurl}</td>
                        </tr>
                      </tbody>
                    </Table>
                    </div>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <p className="text-center">Loading ...</p>
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <h2>Related transactions info</h2>
              <Card>
                <h4 className="text-center"> No records found</h4>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TransInfo;
