// ./components/BarChart.js
import Card from "react-bootstrap/Card";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";

const BarChart = () => {

 //   const barcolor = "rgb(255, 99, 132)";
  //   const backgroundCol = "rgb(255, 99, 132)";
  const [value, setValue] = useState("react");
  const [label, setLabels] = useState([]);
  const [dat, setData] = useState([]);
  const [explorerData, setExplorerData] = useState([]);

  function getSelectedValue(val) {
    //console.log(val);
    getResponse(val);
  }

  async function getResponse(num) {
    const res = await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/DatewiseTransactions?input=" + num
    )
      .then((res) => res.json())
      .then((data) => {
       // console.log(data);
        setLabels(data.map((x) => x.key));
        setData(data.map((x) => x.value));
        setExplorerData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
      getResponse(3);
  }, []);

  const data = {
    labels: label,
    datasets: [
      {
        label: "Transactions",
        backgroundColor: ["#252366"],
        borderColor: ["#252366", "#ffffff"],
        data: dat,
      },
    ],
  };


  // const labels = [
  //   "November",
  //   "December",
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  // ];
  // // const borderColor = ["rgba(255,225,225,1)"],
  // // const graphbg = ["#252366"];
  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: "Live nodes",
  //       backgroundColor: ["#252366"],
  //       borderColor: ["rgba(255,225,225,1)"],
  //       borderWidth: 1,
  //       data: [300, 1000, 1500, 2500, 2000, 3000, 4500],
  //     },
  //   ],
  // };
  return (
    <div>
      <Card className="">
        <Card.Body>
        <div className="row">
            <div className="col-md-10 offset-md-1">

            
          <div className="filter mb-3">
            <h5>Transactions graph</h5>
            <div>
              <Form.Select
                size="sm"
                onChange={(event) => getSelectedValue(event.target.value)}
                defaultValue={value}
              >
                <option value={3}>Daily</option>
                {/*  <option value={2}>Monthly</option>
                <option value={3}>Weekly</option>
                <option value={4}>Quarterly</option> */}
                <option value={4}>Monthly</option>
                {/* <option value={5}>Yearly</option> */}
                {/* <option value={5}>Yearly</option> */}
              </Form.Select>
            </div>
          </div>
          {data != null ?   <Bar data={data} />: <p>Loading...</p>}
          </div>
          </div>
          {/* <div className="filter mb-3">
            <h5>Transactions graph</h5>
            <div>-</div>
          </div>
          <Bar data={data} /> */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default BarChart;
