import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

const DataInfo = () => {
  const { id } = useParams();
  const [contData, setExplorerData] = useState([]);

  async function getResponse() {
    await fetch("https://rubix-explorer-api.azurewebsites.net/api/Explorer/datatokenInfo/" + id)
      .then((res) => res.json())
      .then((data) => {
       // console.log(data);
        setExplorerData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getResponse();
    const interval = setInterval(() => {
      getResponse();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section className="section-padding minheight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Data token transaction info</h2>
              <Card>
                {contData.transaction_id != null ? (
                  <Card.Body>
                    <div className="table-responsive">
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Transaction id:</td>
                          <td>{contData.transaction_id}</td>
                        </tr>
                        <tr>
                          <td>Commiter:</td>
                          <td>{contData.commiter}</td>
                        </tr>
                        <tr>
                          <td>Volume:</td>
                          <td>{contData.volume}</td>
                        </tr>
                        <tr>
                          <td>Creation time:</td>
                          <td>{contData.creation_time}</td>
                        </tr>
                        <tr>
                          <td>Pledger DID:</td>
                          <td className="breakword">{contData.quorum_list}</td>
                        </tr>
                        <tr>
                          <td>Creator DID:</td>
                          <td className="breakword">{contData.datatokens}</td>
                        </tr>
                      </tbody>
                    </Table>
                    </div>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <p className="text-center">Loading..</p>
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DataInfo;
