import React, { useEffect, useState } from "react";
import { BiTransferAlt } from "react-icons/bi";
import { AiFillDashboard, AiFillDollarCircle } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import { GiToken, GiCycle, GiNodular } from "react-icons/gi";
import { NumericFormat } from "react-number-format";

const Stats = () => {
  const [explorerData, setExplorerData] = useState({
    curculatingSupplyCount: "**",
    rubixPrice: "**",
    rubixUsersCount: "**",
    tokensCount: "**",
    transactionsCount: "**",
  });

  async function getResponse() {
    const res = await fetch(
      "https://rubix-explorer-api.azurewebsites.net/api/Explorer/Cards?input=1"
    ).then((res) => res.json());
   // console.log(res);
    setExplorerData(await res);
  }

  // useEffect(() => {
  //     getResponse();
  // }, []);



  useEffect(() => {
    getResponse();
    const interval = setInterval(() => {
      getResponse();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <Card>
              <div className="container-fluid">
              <div className="row">
                <div className="col cusbord">
                <div className="box bb">
                      <div className="boxicon">
                        <AiFillDollarCircle />
                      </div>
                      <div className="content">
                        <h5>RBT price</h5>
                        <p>${explorerData.rubixPrice}</p>
                      </div>
                    </div>
                </div>
                <div className="col cusbord">
                <div className="box bb">
                      <div className="boxicon">
                        <GiCycle />
                      </div>
                      <div className="content">
                        <h5>Total supply</h5>
                        <p>
                          {explorerData.curculatingSupplyCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                </div>
                <div className="col cusbord">
                <div className="box">
                      <div className="boxicon">
                        <AiFillDashboard />
                      </div>
                      <div className="content">
                        <h5>Final supply</h5>
                        <p>51.4M</p>
                      </div>
                    </div>
                </div>
                <div className="col cusbord">
                <div className="box">
                      <div className="boxicon">
                        <BiTransferAlt />
                      </div>
                      <div className="content">
                        <h5>Transactions</h5>
                        <p>
                          {explorerData.transactionsCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                </div>
                <div className="col">
                <div className="box nb">
                      <div className="boxicon">
                        <GiNodular />
                      </div>
                      <div className="content">
                        <h5>Total DIDs</h5>
                        <p>
                          {explorerData.rubixUsersCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                </div>
              </div>
                {/* <div className="row ">
                  <div className="col-md-6 cusbord">
                    <div className="box bb">
                      <div className="boxicon">
                        <AiFillDollarCircle />
                      </div>
                      <div className="content">
                        <h5>RBT price</h5>
                        <p>${explorerData.rubixPrice}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="box bb">
                      <div className="boxicon">
                        <GiCycle />
                      </div>
                      <div className="content">
                        <h5>Total supply</h5>
                        <p>
                          {explorerData.curculatingSupplyCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 cusbord">
                    <div className="box">
                      <div className="boxicon">
                        <AiFillDashboard />
                      </div>
                      <div className="content">
                        <h5>Final supply</h5>
                        <p>51.4M</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="box nb">
                      <div className="boxicon">
                        <BiTransferAlt />
                      </div>
                      <div className="content">
                        <h5>Transactions</h5>
                        <p>
                          {explorerData.transactionsCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 cusbord">
                    <div className="box">
                      <div className="boxicon">
                        <GiToken />
                      </div>
                      <div className="content">
                        <h5>Tokens mined</h5>
                        <p>
                          {explorerData.tokensCount.toLocaleString(undefined)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <div className="boxicon">
                        <GiNodular />
                      </div>
                      <div className="content">
                        <h5>Live nodes</h5>
                        <p>
                          {explorerData.rubixUsersCount.toLocaleString(
                            undefined
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
